import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import * as moment from 'moment';
import { Meta } from '@angular/platform-browser';
import { CookieService } from '../../cookie.service';
import { MatDatepicker } from '@angular/material/datepicker';

@Component({
  selector: 'app-apply',
  templateUrl: './apply.component.html',
  styleUrls: ['./apply.component.scss'],
})
export class ApplyComponent {
  @ViewChild('employedPicker') employedPicker!: MatDatepicker<Date>;
  @ViewChild('dobPicker') dobPicker!: MatDatepicker<Date>;

  isDisabled: boolean = true;
  currentStep = 0;
  maxSteps = 3;
  allFormData = {};
  submitted = false;
  invalidHKID = false;
  // bankOneNameError = false;
  // bankOneLoanAmtError = false;
  // bankOneLoanPeriodError = false;
  // bankTwoNameError = false;
  // bankTwoLoanAmtError = false;
  // bankTwoLoanPeriodError = false;
  // bankThreeNameError = false;
  // bankThreeLoanAmtError = false;
  // bankThreeLoanPeriodError = false;
  // bankFourNameError = false;
  // bankFourLoanAmtError = false;
  // bankFourLoanPeriodError = false;
  // bankFiveNameError = false;
  // bankFiveLoanAmtError = false;
  // bankFiveLoanPeriodError = false;
  bankError = false;
  oneForm: FormGroup;
  sixForm: FormGroup;
  formArray!: FormGroup[];
  formDataArray: any[] = [];
  formOne = {
    loanAmt: false,
    loanMonth: false,
    identifyNo: false,
    cname: false,
    ename: false,
    dob: false,
    address: false,
    resStatus: false,
    profession: false,
    salary: false,
    grainDelForm: false,
    salaryForm: false,
    employed: false,
    companyName: false,
  };
  formSix = {
    credit_bank_name: false,
    account_no: false,
    agreeOne: false,
    agreeTwo: false,
  };
  formBank = {
    bank_name: false,
    loan_amount: false,
    loan_tenor: false,
  };
  ngOnInit(): void {
    // Initialize your form array
    this.formArray = [this.createRow()];
  }
  // replaceNonNumeric(value: string) {
  //   return value.replace(/[^0-9]/g, '');
  // }
  // Function to update loan amount field
  updateLoanAmt(row: FormGroup) {
    this.resetError();

    const loanAmtControl = row.get('loan_amount');
    if (loanAmtControl) {
      loanAmtControl.setValue(loanAmtControl.value.replace(/[^0-9]/g, ''));
    }
  }

  // Function to update loan period field
  updateLoanPeriod(row: FormGroup) {
    const loanPeriodControl = row.get('loan_tenor');
    if (loanPeriodControl) {
      loanPeriodControl.setValue(loanPeriodControl.value.replace(/[^0-9]/g, ''));
    }
    this.resetError();
  }
  createRow(): FormGroup {
    return this.fb.group({
      bank_name: ['', Validators.required],
      loan_amount: ['', Validators.required],
      loan_tenor: ['', Validators.required],
    });
  }

  // Function to add a new row
  addRow() {
    this.formArray.push(this.createRow());
  }

  // Function to remove a row
  removeRow(index: number) {
    this.formArray.splice(index, 1);
    this.resetError();
  }
  openDatePicker(datepicker: MatDatepicker<Date>) {
    datepicker.open();
  }
  requiredAndNotZero(control: AbstractControl): ValidationErrors | null {
    const selectedValue = control.value;
    if (selectedValue === 0 || selectedValue === '0') {
      return { requiredAndNotZero: true };
    }
    return null;
  }
  atLeastOneCheckboxSelected(control: AbstractControl): ValidationErrors | null {
    const agreeOne = control.get('agreeOne')?.value;
    const agreeTwo = control.get('agreeTwo')?.value;

    if (!agreeOne || !agreeTwo) {
      return { atLeastOneCheckboxSelected: true };
    }

    return null;
  }
  hkidValidator() {
    const strValidChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const str = this.oneForm.get('identifyNo')!.value.toUpperCase();

    if (!str || str.length < 8) {
      return (this.invalidHKID = true);
    }

    const hkidPat = /^([A-Z]{1,2})([0-9]{6})([A0-9])$/;
    const matchArray = str.match(hkidPat);

    if (!matchArray) {
      return (this.invalidHKID = true);
    }

    const charPart = matchArray[1];
    const numPart = matchArray[2];
    const checkDigit = matchArray[3];

    let checkSum = 0;

    if (charPart.length === 2) {
      checkSum += 9 * (10 + strValidChars.indexOf(charPart.charAt(0)));
      checkSum += 8 * (10 + strValidChars.indexOf(charPart.charAt(1)));
    } else {
      checkSum += 9 * 36;
      checkSum += 8 * (10 + strValidChars.indexOf(charPart));
    }

    for (let i = 0, j = 7; i < numPart.length; i++, j--) {
      checkSum += j * parseInt(numPart.charAt(i), 10);
    }

    const remaining = checkSum % 11;
    const verify = remaining === 0 ? 0 : 11 - remaining;

    if (verify === +checkDigit || (verify === 10 && checkDigit === 'A')) {
      return null;
    } else {
      return (this.invalidHKID = true);
    }
  }
  constructor(
    private fb: FormBuilder,
    private readonly metaService: Meta,
    private cookieService: CookieService,
  ) {
    var canonicalLink = document.querySelector('link[rel="canonical"]');

    if (canonicalLink) {
      (canonicalLink as HTMLLinkElement).href = 'https://creditprohk.com/apply';
    } else {
      var newCanonicalLink = document.createElement('link');
      newCanonicalLink.rel = 'canonical';
      newCanonicalLink.href = 'https://creditprohk.com/apply';

      document.head.appendChild(newCanonicalLink);
    }
    this.oneForm = this.fb.group({
      loanAmt: ['', Validators.required],
      identifyNo: ['', [Validators.required]],
      loanMonth: ['', Validators.required],
      cname: ['', [Validators.required]],
      ename: ['', Validators.required],
      dob: ['', Validators.required],
      address: ['', [Validators.required]],
      resStatus: ['', [Validators.required, this.requiredAndNotZero]],
      profession: ['', [Validators.required]],
      salary: ['', Validators.required],
      grainDelForm: ['', [Validators.required, this.requiredAndNotZero]],
      salaryForm: ['', [Validators.required, this.requiredAndNotZero]],
      employed: ['', [Validators.required]],
      companyName: ['', Validators.required],
    });
    this.sixForm = this.fb.group({
      hk_id_card_front: [null], // Use null to store file data
      address_proof: [null],
      salary_proof: [null],
      credit_bank_name: ['', [Validators.required, this.requiredAndNotZero]],
      other_document: [null], // Use null for file input
      account_no: ['', Validators.required],
      checkboxGroup: this.fb.group(
        {
          agreeOne: [false],
          agreeTwo: [false],
        },
        { validator: this.atLeastOneCheckboxSelected },
      ),
    });
  }

  handleFileInput(controlName: string, event: Event) {
    const inputElement = event.target as HTMLInputElement;

    if (inputElement.files) {
      const file = inputElement.files[0];
      const maxFileSizeInBytes = 1 * 1024 * 1024;
      if (file && file.size > maxFileSizeInBytes) {
        this.sixForm.get(controlName)?.setErrors({ fileSizeExceeded: true });
        inputElement.value = '';
      } else {
        this.sixForm.get(controlName)?.setErrors(null);
        this.sixForm.get(controlName)!.setValue(file);
      }
    }
  }

  handleSelect(controlName: string, event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    this.sixForm.get(controlName)!.setValue(selectElement.value);
  }
  async nextStep() {
    if (this.currentStep <= this.maxSteps - 1) {
      switch (this.currentStep) {
        case 0:
          return this.submitFormOne();
        case 1:
          return this.bankSubmit();
        case 2:
          return this.submitFormThree();
        default:
          break;
      }
    }
  }
  async submitFormOne() {
    this.formOne.loanAmt = true;
    this.formOne.loanMonth = true;
    this.formOne.cname = true;
    this.formOne.ename = true;
    this.formOne.dob = true;
    this.formOne.address = true;
    this.formOne.resStatus = true;
    this.formOne.profession = true;
    this.formOne.salary = true;
    this.formOne.grainDelForm = true;
    this.formOne.salaryForm = true;
    this.formOne.employed = true;
    this.formOne.companyName = true;
    if (this.hkidValidator()) {
      return;
    }
    const userId = localStorage.getItem('user_id')?.toString();
    if (this.oneForm.valid) {
      this.allFormData = {
        ...this.allFormData,
        loan_amount: this.oneForm.value.loanAmt,
        loan_tenor: this.oneForm.value.loanMonth,
        hk_id_number: this.oneForm.value.identifyNo,
        chinese_name: this.oneForm.value.cname,
        english_name: this.oneForm.value.ename,
        birth_date: moment(this.oneForm.value.dob, 'YYYY/MM/DD').format('YYYY-MM-DD'),
        residential_address: this.oneForm.value.address,
        property_ownership: this.oneForm.value.resStatus,
        profession: this.oneForm.value.profession,
        salary_amount: this.oneForm.value.salary,
        salary_type: this.oneForm.value.grainDelForm,
        payroll_method: this.oneForm.value.salaryForm,
        employment_start_date: moment(this.oneForm.value.employed, 'YYYY/MM/DD').format('YYYY-MM-DD'),
        company_name: this.oneForm.value.companyName,
        user: userId,
        loan_type: 'Personal',
      };
      console.log(this.allFormData, 'formData');
      this.currentStep++;
    } else {
      const loanAmtCont = this.oneForm.get('loanAmt');
      const loanMonthCont = this.oneForm.get('loanMonth');
      const cnameCont = this.oneForm.get('cname');
      const enameCont = this.oneForm.get('ename');
      const dobCont = this.oneForm.get('dob');
      const addressCont = this.oneForm.get('address');
      const resStatusCont = this.oneForm.get('resStatus');
      const professionCont = this.oneForm.get('profession');
      const salaryCont = this.oneForm.get('salary');
      const grainDelFormCont = this.oneForm.get('grainDelForm');
      const salaryFormCont = this.oneForm.get('salaryForm');
      const employedCont = this.oneForm.get('employed');
      const companyNameCont = this.oneForm.get('companyName');
      if (loanAmtCont) {
        this.formOne.loanAmt = loanAmtCont.invalid;
      }
      if (loanMonthCont) {
        this.formOne.loanMonth = loanMonthCont.invalid;
      }
      if (cnameCont) {
        this.formOne.cname = cnameCont.invalid;
      }
      if (enameCont) {
        this.formOne.ename = enameCont.invalid;
      }
      if (dobCont) {
        this.formOne.dob = dobCont.invalid;
      }
      if (addressCont) {
        this.formOne.address = addressCont.invalid;
      }
      if (resStatusCont) {
        this.formOne.resStatus = resStatusCont.invalid;
      }
      if (professionCont) {
        this.formOne.profession = professionCont.invalid;
      }
      if (salaryCont) {
        this.formOne.salary = salaryCont.invalid;
      }
      if (grainDelFormCont) {
        this.formOne.grainDelForm = grainDelFormCont.invalid;
      }
      if (salaryFormCont) {
        this.formOne.salaryForm = salaryFormCont.invalid;
      }
      if (employedCont) {
        this.formOne.employed = employedCont.invalid;
      }
      if (companyNameCont) {
        this.formOne.companyName = companyNameCont.invalid;
      }
    }
  }
  // async submitFormTwo() {
  //   var bank_details = [];
  //   this.resetError();
  //   if (
  //     this.twoForm.value.bankOneName !== '' &&
  //     this.twoForm.value.bankOneLoanAmt !== '' &&
  //     this.twoForm.value.bankOneLoanPeriod !== ''
  //   ) {
  //     bank_details.push({
  //       bank_name: this.twoForm.value.bankOneName,
  //       loan_amount: parseInt(this.twoForm.value.bankOneLoanAmt),
  //       loan_tenor: parseInt(this.twoForm.value.bankOneLoanPeriod),
  //     });
  //   } else {
  //     if (
  //       this.twoForm.value.bankOneName !== '' ||
  //       this.twoForm.value.bankOneLoanAmt !== '' ||
  //       this.twoForm.value.bankOneLoanPeriod !== ''
  //     ) {
  //       if (this.twoForm.value.bankOneName === '') {
  //         this.bankOneNameError = true;
  //       }

  //       if (this.twoForm.value.bankOneLoanAmt === '') {
  //         this.bankOneLoanAmtError = true;
  //       }
  //       if (this.twoForm.value.bankOneLoanPeriod === '') {
  //         this.bankOneLoanPeriodError = true;
  //       }
  //     }
  //   }
  //   if (
  //     this.twoForm.value.bankTwoName !== '' &&
  //     this.twoForm.value.bankTwoLoanAmt !== '' &&
  //     this.twoForm.value.bankTwoLoanPeriod !== ''
  //   ) {
  //     bank_details.push({
  //       bank_name: this.twoForm.value.bankTwoName,
  //       loan_amount: parseInt(this.twoForm.value.bankTwoLoanAmt),
  //       loan_tenor: parseInt(this.twoForm.value.bankTwoLoanPeriod),
  //     });
  //   } else {
  //     if (
  //       this.twoForm.value.bankTwoName !== '' ||
  //       this.twoForm.value.bankTwoLoanAmt !== '' ||
  //       this.twoForm.value.bankTwoLoanPeriod !== ''
  //     ) {
  //       if (this.twoForm.value.bankTwoName === '') {
  //         this.bankTwoNameError = true;
  //       }

  //       if (this.twoForm.value.bankTwoLoanAmt === '') {
  //         this.bankTwoLoanAmtError = true;
  //       }
  //       if (this.twoForm.value.bankTwoLoanPeriod === '') {
  //         this.bankTwoLoanPeriodError = true;
  //       }
  //     }
  //   }
  //   if (
  //     this.twoForm.value.bankThreeName !== '' &&
  //     this.twoForm.value.bankThreeLoanAmt !== '' &&
  //     this.twoForm.value.bankThreeLoanPeriod !== ''
  //   ) {
  //     bank_details.push({
  //       bank_name: this.twoForm.value.bankThreeName,
  //       loan_amount: parseInt(this.twoForm.value.bankThreeLoanAmt),
  //       loan_tenor: parseInt(this.twoForm.value.bankThreeLoanPeriod),
  //     });
  //   } else {
  //     if (
  //       this.twoForm.value.bankThreeName !== '' ||
  //       this.twoForm.value.bankThreeLoanAmt !== '' ||
  //       this.twoForm.value.bankThreeLoanPeriod !== ''
  //     ) {
  //       if (this.twoForm.value.bankThreeName === '') {
  //         this.bankThreeNameError = true;
  //       }

  //       if (this.twoForm.value.bankThreeLoanAmt === '') {
  //         this.bankThreeLoanAmtError = true;
  //       }
  //       if (this.twoForm.value.bankThreeLoanPeriod === '') {
  //         this.bankThreeLoanPeriodError = true;
  //       }
  //     }
  //   }
  //   if (
  //     this.twoForm.value.bankFourName !== '' &&
  //     this.twoForm.value.bankFourLoanAmt !== '' &&
  //     this.twoForm.value.bankFourLoanPeriod !== ''
  //   ) {
  //     bank_details.push({
  //       bank_name: this.twoForm.value.bankFourName,
  //       loan_amount: parseInt(this.twoForm.value.bankFourLoanAmt),
  //       loan_tenor: parseInt(this.twoForm.value.bankFourLoanPeriod),
  //     });
  //   } else {
  //     if (
  //       this.twoForm.value.bankFourName !== '' ||
  //       this.twoForm.value.bankFourLoanAmt !== '' ||
  //       this.twoForm.value.bankFourLoanPeriod !== ''
  //     ) {
  //       if (this.twoForm.value.bankFourName === '') {
  //         this.bankFourNameError = true;
  //       }

  //       if (this.twoForm.value.bankFourLoanAmt === '') {
  //         this.bankFourLoanAmtError = true;
  //       }
  //       if (this.twoForm.value.bankFourLoanPeriod === '') {
  //         this.bankFourLoanPeriodError = true;
  //       }
  //     }
  //   }
  //   if (
  //     this.twoForm.value.bankFiveName !== '' &&
  //     this.twoForm.value.bankFiveLoanAmt !== '' &&
  //     this.twoForm.value.bankFiveLoanPeriod !== ''
  //   ) {
  //     bank_details.push({
  //       bank_name: this.twoForm.value.bankFiveName,
  //       loan_amount: parseInt(this.twoForm.value.bankFiveLoanAmt),
  //       loan_tenor: parseInt(this.twoForm.value.bankFiveLoanPeriod),
  //     });
  //   } else {
  //     if (
  //       this.twoForm.value.bankFiveName !== '' ||
  //       this.twoForm.value.bankFiveLoanAmt !== '' ||
  //       this.twoForm.value.bankFiveLoanPeriod !== ''
  //     ) {
  //       if (this.twoForm.value.bankFiveName === '') {
  //         this.bankFiveNameError = true;
  //       }

  //       if (this.twoForm.value.bankFiveLoanAmt === '') {
  //         this.bankFiveLoanAmtError = true;
  //       }
  //       if (this.twoForm.value.bankFiveLoanPeriod === '') {
  //         this.bankFiveLoanPeriodError = true;
  //       }
  //     }
  //   }

  //   if (
  //     this.bankOneNameError ||
  //     this.bankOneLoanAmtError ||
  //     this.bankOneLoanPeriodError ||
  //     this.bankTwoNameError ||
  //     this.bankTwoLoanAmtError ||
  //     this.bankTwoLoanPeriodError ||
  //     this.bankThreeNameError ||
  //     this.bankThreeLoanAmtError ||
  //     this.bankThreeLoanPeriodError ||
  //     this.bankFourNameError ||
  //     this.bankFourLoanAmtError ||
  //     this.bankFourLoanPeriodError ||
  //     this.bankFiveNameError ||
  //     this.bankFiveLoanAmtError ||
  //     this.bankFiveLoanPeriodError
  //   ) {
  //     return;
  //   }
  //   this.allFormData = {
  //     ...this.allFormData,
  //     bank_details: bank_details,
  //   };
  //   console.log(this.allFormData, 'formData5');
  //   this.currentStep++;
  //   // setTimeout(() => {
  //   //   const canvasElement = document.getElementById('signaturePad');
  //   //   console.log(canvasElement, 'ste');
  //   //   this.initializeSignaturePad(canvasElement as HTMLCanvasElement);
  //   // }, 100);
  // }
  async submitFormThree() {
    this.formSix.account_no = true;
    this.formSix.credit_bank_name = true;
    this.formSix.agreeOne = true;
    this.formSix.agreeTwo = true;
    const adTrackUrlData = this.cookieService.getCookie('addUrl');
    if (this.sixForm.valid) {
      this.submitted = true;
      const formData = new FormData();
      const loanApplicationData: any = this.allFormData;
      formData.append('credit_bank_name', this.sixForm.get('credit_bank_name')!.value);
      formData.append('account_no', this.sixForm.get('account_no')!.value);
      if (adTrackUrlData !== null) {
        formData.append('ad_track', adTrackUrlData);
      }
      for (const property in loanApplicationData) {
        if (loanApplicationData.hasOwnProperty(property)) {
          if (Array.isArray(loanApplicationData[property])) {
            loanApplicationData[property].forEach((item: any, index: any) => {
              for (const itemProperty in item) {
                if (item.hasOwnProperty(itemProperty)) {
                  formData.append(`${property}[${index}].${itemProperty}`, item[itemProperty].toString());
                }
              }
            });
          } else {
            formData.append(property, loanApplicationData[property].toString());
          }
        }
      }

      console.log('Form Data:', formData);
      try {
        const response = await fetch('https://backend.creditprohk.com/en/api/apply-loan/', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('id_token')}`,
          },
          body: formData,
        });

        if (response.ok) {
          this.submitted = false;
          window.location.replace('/account');
        } else {
          const data = await response.json();
          alert(data.message);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    } else {
      const accountNoCont = this.sixForm.get('account_no');
      const bankNameCont = this.sixForm.get('credit_bank_name');
      const agreeOneCont = this.sixForm.get('agreeOne');
      const agreeTwoCont = this.sixForm.get('agreeTwo');
      if (accountNoCont) {
        this.formSix.account_no = accountNoCont.invalid;
      }
      if (bankNameCont) {
        this.formSix.credit_bank_name = bankNameCont.invalid;
      }
      if (agreeOneCont) {
        this.formSix.agreeOne = agreeOneCont.invalid;
      }
      if (agreeTwoCont) {
        this.formSix.agreeTwo = agreeTwoCont.invalid;
      }
    }
  }
  previousStep() {
    console.log(this.allFormData, 'formDataPrev');

    if (this.currentStep > 0) {
      this.currentStep--;
    }
  }
  resetError() {
    this.invalidHKID = false;
    this.bankError = false;
    // this.bankOneNameError = false;
    // this.bankOneLoanAmtError = false;
    // this.bankOneLoanPeriodError = false;
    // this.bankTwoNameError = false;
    // this.bankTwoLoanAmtError = false;
    // this.bankTwoLoanPeriodError = false;
    // this.bankThreeNameError = false;
    // this.bankThreeLoanAmtError = false;
    // this.bankThreeLoanPeriodError = false;
    // this.bankFourNameError = false;
    // this.bankFourLoanAmtError = false;
    // this.bankFourLoanPeriodError = false;
    // this.bankFiveNameError = false;
    // this.bankFiveLoanAmtError = false;
    // this.bankFiveLoanPeriodError = false;
    this.formOne = {
      loanAmt: false,
      loanMonth: false,
      identifyNo: false,
      cname: false,
      ename: false,
      dob: false,
      address: false,
      resStatus: false,
      profession: false,
      salary: false,
      grainDelForm: false,
      salaryForm: false,
      employed: false,
      companyName: false,
    };
    this.formSix = {
      credit_bank_name: false,
      account_no: false,
      agreeOne: false,
      agreeTwo: false,
    };
  }
  checkChatLimit() {
    const loanPurposeControl = this.oneForm.get('loanPurpose');
    const value = loanPurposeControl!.value;
    if (value.length > 200) {
      loanPurposeControl?.setValue(value.substring(0, 200));
    }
  }

  bankSubmit() {
    this.bankError = false;
    this.formDataArray = [];
    if (this.formArray.length > 1) {
      for (const row of this.formArray) {
        this.formDataArray.push(row.value);
      }
      for (let item of this.formDataArray) {
        for (let key in item) {
          if (!item[key]) {
            this.bankError = true;
          }
        }
      }
    }
    console.log(this.bankError);
    if (!this.bankError) {
      this.allFormData = {
        ...this.allFormData,
        bank_details: this.formDataArray,
      };
      console.log(this.allFormData, 'formData5');
      this.currentStep++;
    }
  }
}
