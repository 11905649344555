<div class="apply">
  <app-header />
  <div class="apply__cont container">
    <h2 class="apply__cont__title">填寫表格 立即申請</h2>
    <app-form>
      <div class="apply__cont__form">
        <div class="apply__cont__form__header">
          <h2 class="apply__cont__form__title">貸款資料</h2>
          <p class="apply__cont__form__subTitle">
            請先填寫以下所需資料，以加快審批過程。我們或有可能會聯絡閣下提供更多申請所需的資料。
          </p>
        </div>
        <div class="apply__cont__form__progress">
          <a class="apply__cont__form__progress__step apply__cont__form__progress__step___completed">1</a>
          <a
            [ngClass]="{
              apply__cont__form__progress__step: true,
              apply__cont__form__progress__step___completed: currentStep >= 1
            }"
            >2</a
          >
          <a
            [ngClass]="{
              apply__cont__form__progress__step: true,
              apply__cont__form__progress__step___completed: currentStep >= 2
            }"
            >3</a
          >
        </div>
        <form action="" class="step-1" *ngIf="currentStep === 0" [formGroup]="oneForm">
          <div class="grid gap___df grid___same">
            <div
              [ngClass]="{
                input: true,
                input___error: formOne.loanAmt && oneForm.get('loanAmt')?.hasError('required')
              }"
            >
              <label><span>*</span>*申請貸款金額</label>
              <input type="number" placeholder="5000 - 800000" (input)="resetError()" formControlName="loanAmt" />
              <p class="input__errorMsg" *ngIf="oneForm.get('loanAmt')?.hasError('required')">請輸入貸款金額</p>
            </div>
            <div
              [ngClass]="{
                input: true,
                input___error: formOne.loanMonth && oneForm.get('loanMonth')?.hasError('required')
              }"
            >
              <label><span>*</span>供款期</label>
              <input type="number" placeholder="1 - 60 (月)" (input)="resetError()" formControlName="loanMonth" />
              <p class="input__errorMsg" *ngIf="oneForm.get('loanMonth')?.hasError('required')">請輸入供款期(月)</p>
            </div>
          </div>
          <div class="grid gap___df grid___same">
            <div
              [ngClass]="{
                input: true,
                input___error: invalidHKID
              }"
            >
              <label><span>*</span>身份證號碼</label>
              <input
                type="text"
                placeholder="香港身份證號碼: Y7457843"
                (input)="resetError()"
                formControlName="identifyNo"
              />
              <p class="input__errorMsg" *ngIf="invalidHKID">
                請輸入正確的香港身份證號碼，包括"()"內數字，無需輸入"()"
              </p>
            </div>
            <div
              [ngClass]="{
                input: true,
                input___error: formOne.cname && oneForm.get('cname')?.hasError('required')
              }"
            >
              <label>中文姓名</label>
              <input type="text" (input)="resetError()" formControlName="cname" placeholder="如：陳大文" />
              <p class="input__errorMsg" *ngIf="oneForm.get('cname')?.hasError('required')">請輸入中文姓名</p>
            </div>
          </div>
          <div class="grid gap___df grid___same">
            <div
              [ngClass]="{
                input: true,
                input___error: formOne.ename && oneForm.get('ename')?.hasError('required')
              }"
            >
              <label>英文姓名 (* 與身份證相同)</label>
              <input type="text" (input)="resetError()" formControlName="ename" placeholder="如: Chan Tai Man" />
              <p class="input__errorMsg" *ngIf="oneForm.get('ename')?.hasError('required')">請輸入英文姓名</p>
            </div>

            <div
              [ngClass]="{
                input: true,
                input___error: formOne.dob && oneForm.get('dob')?.hasError('required')
              }"
            >
              <label>出生日期</label>
              <mat-form-field>
                <input
                  matInput
                  [matDatepicker]="dobPicker"
                  (input)="resetError()"
                  formControlName="dob"
                  placeholder="點擊選擇年月日 "
                  (focus)="openDatePicker(dobPicker)"
                />
                <mat-datepicker-toggle matIconSuffix [for]="dobPicker"></mat-datepicker-toggle>
                <mat-datepicker #dobPicker></mat-datepicker>
              </mat-form-field>
              <p class="input__errorMsg" *ngIf="oneForm.get('dob')?.hasError('required')">請選擇出生日期</p>
            </div>
          </div>
          <div class="grid gap___df grid___same">
            <div
              [ngClass]="{
                input: true,
                input___error: formOne.address && oneForm.get('address')?.hasError('required')
              }"
            >
              <label>住宅地址</label>
              <input type="text" (input)="resetError()" formControlName="address" />
              <p class="input__errorMsg" *ngIf="oneForm.get('address')?.hasError('required')">請輸入住宅地址</p>
            </div>
            <div
              [ngClass]="{
                input: true,
                input___error:
                  formOne.resStatus &&
                  (oneForm.get('resStatus')?.hasError('required') ||
                    oneForm.get('resStatus')?.hasError('requiredAndNotZero'))
              }"
            >
              <label>住宅狀況</label>
              <select (input)="resetError()" formControlName="resStatus">
                <option value="0">請選擇住宅狀況 </option>
                <option value="1">本人物業</option>
                <option value="2">家人物業</option>
                <option value="3">租住</option>
                <option value="4">其他</option>
              </select>
              <p
                class="input__errorMsg"
                *ngIf="
                  oneForm.get('resStatus')?.hasError('required') ||
                  oneForm.get('resStatus')?.hasError('requiredAndNotZero')
                "
              >
                請選擇住宅狀況
              </p>
            </div>
          </div>
          <div class="grid gap___df grid___same">
            <div
              [ngClass]="{
                input: true,
                input___error: formOne.profession && oneForm.get('profession')?.hasError('required')
              }"
            >
              <label>職業</label>
              <input type="text" (input)="resetError()" formControlName="profession" />
              <p class="input__errorMsg" *ngIf="oneForm.get('profession')?.hasError('required')">請輸入職業</p>
            </div>
            <div
              [ngClass]="{
                input: true,
                input___error: formOne.salary && oneForm.get('salary')?.hasError('required')
              }"
            >
              <label>薪金收入</label>
              <input
                type="number"
                (input)="resetError()"
                formControlName="salary"
                placeholder="日薪/月薪等收入數字 (最好能在銀行記錄顯示)"
              />
              <p class="input__errorMsg" *ngIf="oneForm.get('salary')?.hasError('required')">請輸入薪金收入</p>
            </div>
          </div>
          <div class="grid gap___df grid___same">
            <div
              [ngClass]="{
                input: true,
                input___error:
                  formOne.grainDelForm &&
                  (oneForm.get('grainDelForm')?.hasError('required') ||
                    oneForm.get('grainDelForm')?.hasError('requiredAndNotZero'))
              }"
            >
              <label>出糧形式</label>
              <select (input)="resetError()" formControlName="grainDelForm">
                <option value="0">請選擇出糧形式</option>
                <option value="1">自動轉賬</option>
                <option value="2">現金</option>
                <option value="3">支票</option>
                <option value="4">銀行過戶</option>
              </select>
              <p
                class="input__errorMsg"
                *ngIf="
                  oneForm.get('grainDelForm')?.hasError('required') ||
                  oneForm.get('grainDelForm')?.hasError('requiredAndNotZero')
                "
              >
                請選擇出糧形式
              </p>
            </div>
            <div
              [ngClass]="{
                input: true,
                input___error:
                  formOne.salaryForm &&
                  (oneForm.get('salaryForm')?.hasError('required') ||
                    oneForm.get('salaryForm')?.hasError('requiredAndNotZero'))
              }"
            >
              <label>薪金形式</label>
              <select (input)="resetError()" formControlName="salaryForm">
                <option value="0">請選擇薪金形式</option>
                <option value="1">日薪</option>
                <option value="2">7日薪</option>
                <option value="3">15日薪</option>
                <option value="4">月薪</option>
                <option value="5">全佣薪</option>
                <option value="6">其他</option>
              </select>
              <p
                class="input__errorMsg"
                *ngIf="
                  oneForm.get('salaryForm')?.hasError('required') ||
                  oneForm.get('salaryForm')?.hasError('requiredAndNotZero')
                "
              >
                請選擇薪金形式
              </p>
            </div>
          </div>
          <div class="grid gap___df grid___same">
            <div
              [ngClass]="{
                input: true,
                input___error: formOne.employed && oneForm.get('employed')?.hasError('required')
              }"
            >
              <label>開業/受僱</label>
              <mat-form-field>
                <input
                  matInput
                  [matDatepicker]="employedPicker"
                  (input)="resetError()"
                  formControlName="employed"
                  placeholder="開業/受僱日期"
                  (focus)="openDatePicker(employedPicker)"
                />
                <mat-datepicker-toggle matIconSuffix [for]="employedPicker"></mat-datepicker-toggle>
                <mat-datepicker #employedPicker></mat-datepicker>
              </mat-form-field>
              <p class="input__errorMsg" *ngIf="oneForm.get('employed')?.hasError('required')">請選擇開業/受僱日期 |</p>
            </div>
            <div
              [ngClass]="{
                input: true,
                input___error: formOne.companyName && oneForm.get('companyName')?.hasError('required')
              }"
            >
              <label>公司名稱</label>
              <input type="text" (input)="resetError()" formControlName="companyName" />
              <p class="input__errorMsg" *ngIf="oneForm.get('companyName')?.hasError('required')">請輸入公司名稱</p>
            </div>
          </div>
          <input type="button" value="下一步" class="form-submit-btn" (click)="nextStep()" />
        </form>

        <form
          enctype="multipart/form-data"
          action=""
          (ngSubmit)="bankSubmit()"
          class="step-5"
          *ngIf="currentStep === 1"
        >
          <div class="multirow">
            <div *ngFor="let row of formArray; let i = index" [formGroup]="row">
              <div class="multirow__row">
                <div
                  [ngClass]="{
                    input: true,
                    input___error: formBank.bank_name && row.get('bank_name')?.hasError('required')
                  }"
                >
                  <label>銀行/財務機構</label>
                  <input type="text" formControlName="bank_name" (input)="resetError()" />
                  <p class="input__errorMsg" *ngIf="row.get('bank_name')?.hasError('required')">請輸入資料</p>
                </div>
                <div
                  [ngClass]="{
                    input: true,
                    input___error: formBank.loan_amount && row.get('loan_amount')?.hasError('required')
                  }"
                >
                  <label>貸款/信用額</label>
                  <input type="text" formControlName="loan_amount" pattern="[0-9]*" (input)="updateLoanAmt(row)" />
                  <p class="input__errorMsg" *ngIf="row.get('loan_amount')?.hasError('required')">請輸入資料</p>
                </div>
                <div
                  [ngClass]="{
                    input: true,
                    input___error: formBank.loan_tenor && row.get('loan_tenor')?.hasError('required')
                  }"
                >
                  <label>期數</label>
                  <input
                    type="text"
                    formControlName="loan_tenor"
                    placeholder="循環貸款打'0'"
                    pattern="[0-9]*"
                    (input)="updateLoanPeriod(row)"
                  />
                  <p class="input__errorMsg" *ngIf="row.get('loan_tenor')?.hasError('required')">請輸入資料</p>
                </div>
                <a *ngIf="formArray.length > 1" (click)="removeRow(i)"
                  ><svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="16" cy="16" r="16" fill="url(#paint0_linear_862_1242)" />
                    <path
                      d="M11 16.1074L16 16.1074L21 16.1074"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_862_1242"
                        x1="0"
                        y1="16.1725"
                        x2="32"
                        y2="16.1725"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#8C52FF" />
                        <stop offset="1" stop-color="#5CE1E6" />
                      </linearGradient>
                    </defs>
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <div
            [ngClass]="{
              input: true,
              input___error: this.bankError
            }"
          >
            <p class="input__errorMsg" *ngIf="this.bankError">請填寫所有字段，如果任何行的所有字段為空，則將其刪除</p>
          </div>
          <a (click)="addRow()" class="add-btn"
            ><svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="16" cy="16" r="16" fill="url(#paint0_linear_862_1242)" />
              <path
                d="M11 16.1074L16 16.1074L21 16.1074"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.1074 11L16.1074 16L16.1074 21"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_862_1242"
                  x1="0"
                  y1="16.1725"
                  x2="32"
                  y2="16.1725"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#8C52FF" />
                  <stop offset="1" stop-color="#5CE1E6" />
                </linearGradient>
              </defs>
            </svg>
          </a>
          <!-- <div class="grid gap___df grid___same">
            <div
              [ngClass]="{
                input: true,
                input___error: bankTwoNameError
              }"
            >
              <label>銀行/財務機構</label>
              <input type="text" formControlName="bankTwoName" (input)="resetError()" />
              <p class="input__errorMsg" *ngIf="bankTwoNameError">請輸入資料</p>
            </div>
            <div
              [ngClass]="{
                input: true,
                input___error: bankTwoLoanAmtError
              }"
            >
              <label>貸款/信用額</label>
              <input
                type="text"
                formControlName="bankTwoLoanAmt"
                pattern="[0-9]*"
                (input)="resetError()"
                oninput="this.value = this.value.replace(/[^0-9]/, '')"
              />
              <p class="input__errorMsg" *ngIf="bankTwoLoanAmtError">請輸入資料</p>
            </div>
            <div
              [ngClass]="{
                input: true,
                input___error: bankTwoLoanPeriodError
              }"
            >
              <label>期數</label>
              <input
                type="text"
                formControlName="bankTwoLoanPeriod"
                placeholder="循環貸款打'0'"
                pattern="[0-9]*"
                (input)="resetError()"
                oninput="this.value = this.value.replace(/[^0-9]/, '')"
              />
              <p class="input__errorMsg" *ngIf="bankTwoLoanPeriodError">請輸入資料</p>
            </div>
          </div>
          <div class="grid gap___df grid___same">
            <div
              [ngClass]="{
                input: true,
                input___error: bankThreeNameError
              }"
            >
              <label>銀行/財務機構</label>
              <input type="text" formControlName="bankThreeName" (input)="resetError()" />
              <p class="input__errorMsg" *ngIf="bankThreeNameError">請輸入資料</p>
            </div>
            <div
              [ngClass]="{
                input: true,
                input___error: bankThreeLoanAmtError
              }"
            >
              <label>貸款/信用額</label>
              <input
                type="text"
                formControlName="bankThreeLoanAmt"
                pattern="[0-9]*"
                (input)="resetError()"
                oninput="this.value = this.value.replace(/[^0-9]/, '')"
              />
              <p class="input__errorMsg" *ngIf="bankThreeLoanAmtError">請輸入資料</p>
            </div>
            <div
              [ngClass]="{
                input: true,
                input___error: bankThreeLoanPeriodError
              }"
            >
              <label>期數</label>
              <input
                type="text"
                formControlName="bankThreeLoanPeriod"
                placeholder="循環貸款打'0'"
                pattern="[0-9]*"
                (input)="resetError()"
                oninput="this.value = this.value.replace(/[^0-9]/, '')"
              />
              <p class="input__errorMsg" *ngIf="bankThreeLoanPeriodError">請輸入資料</p>
            </div>
          </div>
          <div class="grid gap___df grid___same">
            <div
              [ngClass]="{
                input: true,
                input___error: bankFourNameError
              }"
            >
              <label>銀行/財務機構</label>
              <input type="text" formControlName="bankFourName" (input)="resetError()" />
              <p class="input__errorMsg" *ngIf="bankFourNameError">請輸入資料</p>
            </div>
            <div
              [ngClass]="{
                input: true,
                input___error: bankFourLoanAmtError
              }"
            >
              <label>貸款/信用額</label>
              <input
                type="text"
                formControlName="bankFourLoanAmt"
                pattern="[0-9]*"
                (input)="resetError()"
                oninput="this.value = this.value.replace(/[^0-9]/, '')"
              />
              <p class="input__errorMsg" *ngIf="bankFourLoanAmtError">請輸入資料</p>
            </div>
            <div
              [ngClass]="{
                input: true,
                input___error: bankFourLoanPeriodError
              }"
            >
              <label>期數</label>
              <input
                type="text"
                formControlName="bankFourLoanPeriod"
                placeholder="循環貸款打'0'"
                pattern="[0-9]*"
                (input)="resetError()"
                oninput="this.value = this.value.replace(/[^0-9]/, '')"
              />
              <p class="input__errorMsg" *ngIf="bankFourLoanPeriodError">請輸入資料</p>
            </div>
          </div>
          <div class="grid gap___df grid___same">
            <div
              [ngClass]="{
                input: true,
                input___error: bankFiveNameError
              }"
            >
              <label>銀行/財務機構</label>
              <input type="text" formControlName="bankFiveName" (input)="resetError()" />
              <p class="input__errorMsg" *ngIf="bankFiveNameError">請輸入資料</p>
            </div>
            <div
              [ngClass]="{
                input: true,
                input___error: bankFiveLoanAmtError
              }"
            >
              <label>貸款/信用額</label>
              <input
                type="text"
                formControlName="bankFiveLoanAmt"
                pattern="[0-9]*"
                (input)="resetError()"
                oninput="this.value = this.value.replace(/[^0-9]/, '')"
              />
              <p class="input__errorMsg" *ngIf="bankFiveLoanAmtError">請輸入資料</p>
            </div>
            <div
              [ngClass]="{
                input: true,
                input___error: bankFiveLoanPeriodError
              }"
            >
              <label>期數</label>
              <input
                type="text"
                formControlName="bankFiveLoanPeriod"
                placeholder="循環貸款打'0'"
                pattern="[0-9]*"
                (input)="resetError()"
                oninput="this.value = this.value.replace(/[^0-9]/, '')"
              />
              <p class="input__errorMsg" *ngIf="bankFiveLoanPeriodError">請輸入資料</p>
            </div>
          </div> -->
          <div class="input__row grid grid___space-between">
            <a class="form-back-btn" (click)="previousStep()">上一步</a>
            <input type="submit" value="下一步" class="form-submit-btn" />
          </div>
        </form>

        <form action="" class="step-6" *ngIf="currentStep === 2" [formGroup]="sixForm">
          <div class="grid gap___df grid___same">
            <div
              [ngClass]="{
                input: true,
                input___error:
                  formSix.credit_bank_name &&
                  (sixForm.get('credit_bank_name')?.hasError('required') ||
                    sixForm.get('credit_bank_name')?.hasError('requiredAndNotZero'))
              }"
            >
              <label><span>*</span>收款銀行</label>
              <select (change)="handleSelect('credit_bank_name', $event)" formControlName="credit_bank_name">
                <option value="0">選擇以下銀行</option>
                <option value="1">003- 渣打銀行(香港)有限公司</option>
                <option value="2">004-香港上海匯豐銀行有限公司</option>
                <option value="3">009-中國建設銀行(亞洲)股份有限公司<</option>
                <option value="4">012-中國銀行香港有限公司</option>
                <option value="5">015-東亞銀行有限公司</option>
                <option value="6">016-星展銀行香港有限公司</option>
                <option value="7">018-中信銀行(國際)有限公司</option>
                <option value="8">004-020-招商永隆銀行有限公司</option>
                <option value="9">024-恒生銀行有限公司</option>
                <option value="10">025-上海商業銀行有限公司</option>
                <option value="11">028-大眾銀行(香港)有限公司</option>
                <option value="12">035-華僑永亨銀行有限公司</option>
                <option value="13">038-大有銀行有限公司</option>
                <option value="14">039-集友银行有限公司</option>
                <option value="15">040-大新银行有限公司</option>
                <option value="16">041-创舆银行有限公司</option>
                <option value="17">043-南洋商業行银有限公司</option>
                <option value="18">072-中國工商银行(亞洲）有限公司</option>
                <option value="19">128-富邦银行(香港)有限公司</option>
                <option value="20">250-花旗银行(香港)有限公司</option>
                <option value="21">382-交通银行(香港)有限公司</option>
                <option value="22">387-眾安银行有限公司ZA BANK</option>
                <option value="23">388 - LIVI VB LIMITED</option>
                <option value="24">389 -MOX BANK LIMITED</option>
                <option value="25">390-匯立银行有限公司 WELAB BANK LIMITED</option>
                <option value="26">395 - 天星銀行有限公司 AISTAR BANK</option>
              </select>
              <p
                class="input__errorMsg"
                *ngIf="
                  sixForm.get('credit_bank_name')?.hasError('required') ||
                  sixForm.get('credit_bank_name')?.hasError('requiredAndNotZero')
                "
              >
                請選擇收款銀行
              </p>
            </div>
            <div
              [ngClass]="{
                input: true,
                input___error: formSix.account_no && sixForm.get('account_no')?.hasError('required')
              }"
            >
              <label><span>*</span>戶口號碼</label>
              <input type="number" formControlName="account_no" />
              <p class="input__errorMsg" *ngIf="sixForm.get('account_no')?.hasError('required')">請輸入銀行戶口號碼</p>
            </div>
          </div>
          <div formGroupName="checkboxGroup">
            <div class="checkbox">
              <input type="checkbox" id="term1" name="term1" formControlName="agreeOne" />
              <label for="term1">
                本人同意CREDIT PRO
                FINANCE可按私隱政策及收集個人資料聲明中所訂把本人的個人資料用於直接促銷及/或提供予其他人士作直接促銷之用
              </label>
            </div>
            <div class="checkbox">
              <input type="checkbox" id="term2" name="term2" formControlName="agreeTwo" />
              <label for="term2">
                若閣下不同意下述聲明，就有關貸款申請，請親自向貴公司分行查詢。關於本人/吾等向貴公司貸款一事，現謹確認：在本貸款申請內，本人/吾等沒有因促致、洽商、取得或申請該筆貸款，或因擔保或保證該筆貸款的償還，或由於與該等事務有關，而與任何人或公司達成或簽訂了任何協議（借款人與其委任的律師純粹為提供法律服務而達成或簽訂的協議除外）。
              </label>
            </div>
          </div>
          <p
            class="red"
            *ngIf="
              formSix.agreeOne &&
              formSix.agreeTwo &&
              sixForm.get('checkboxGroup')?.hasError('atLeastOneCheckboxSelected')
            "
          >
            請確認並點擊以上條款
          </p>
          <div class="input__row grid grid___space-between">
            <a class="form-back-btn" (click)="previousStep()">上一步</a>
            <button type="button" (click)="submitFormThree()" class="form-submit-btn" *ngIf="!submitted">
              提交申請
            </button>
            <button type="button" disabled class="form-submit-btn" *ngIf="submitted">提交中</button>
          </div>
        </form>
        <div *ngIf="submitted" class="loaderBody">
          <span class="loaderBody__loader"></span>
        </div>
      </div>
    </app-form>
  </div>
  <app-online-process />
  <app-footer />
</div>
